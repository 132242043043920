import React from 'react';
import theme from '../../js/styles/theme.style';
import { makeStyles, styled } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

const commonButtonStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  height: '40px',
  borderRadius: (props) => props.borderradius,
  fontSize: theme.FONT_SIZE_LARGE,
  fontFamily: theme.FONT_FAMILY_BOLD,
  fontWeight: 'bold',
  cursor: 'pointer',
  color: theme.BLACK_BLUE,
  backgroundColor: theme.transparent,
  width: props => props.width
};

const TextButton = styled(ButtonBase)((props) => {
  return ({
    ...commonButtonStyles,

    color: props.color || theme.BLACK_BLUE,
    '&:hover': {
      backgroundColor: theme.OPACITY_BLACK_BLUE
    },
    '&:disabled': {
      backgroundColor: theme.GREY,
      cursor: 'default',
      color: theme.TEXT_GREY_LIGHTER
    }
  });
});

const ContainedButton = styled(ButtonBase)((props) => {
  return ({
    ...commonButtonStyles,

    color: theme.WHITE,
    backgroundColor: props.color || theme.BLUE,
    borderRadius: props.borderradius,

    '&:hover': {
      backgroundColor: props.color || theme.BLUE_LIGHT_1,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
    },
    '&:disabled': {
      backgroundColor: theme.GREY,
      cursor: 'default',
      color: theme.TEXT_GREY_LIGHTER,
      boxShadow: 'none'
    }
  });
});

const OutlinedButton = styled(ButtonBase)((props) => {
  return ({
    ...commonButtonStyles,
    color: theme.BLUE,
    border: `1px solid ${theme.blue.background}`,
    boxShadow: `0px 4px 8px 0px ${theme.blue[50]}`,
    backgroundColor: theme.transparent,
    borderRadius: props.borderradius,

    '&:hover': {
      backgroundColor: theme.OPACITY_BLUE,
      border: `1px solid ${theme.blue.background}`
    },
    '&:disabled': {
      backgroundColor: theme.GREY,
      cursor: 'default',
      color: theme.TEXT_GREY_LIGHTER
    }
  });
});

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    marginRight: 8
  }
});

const MyButton = (props) => {
  const { variant, ...other } = props;

  if (variant === 'contained') {
    return <ContainedButton {...other} focusRipple={true} />;
  } else if (variant === 'outlined') {
    return <OutlinedButton {...other} focusRipple={true} />;
  } else {
    return <TextButton {...other} focusRipple={true} />;
  }
};

const BaseButton = ({
  text,
  icon,
  disabled,
  onClick,
  loading,
  color,
  width,
  borderRadius = '100px',
  variant,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MyButton
      color={color}
      borderradius={borderRadius}
      variant={variant}
      disabled={disabled || loading}
      width={width}
      onClick={onClick}
      {...rest}
    >
      {icon &&
      <div
        className={classes.icon}
      >
        {icon}
      </div>}
      {text && !loading &&
      <div
        className={classes.text}
      >
        {text}
      </div>}
    </MyButton>
  );
};

export default BaseButton;
