import React from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import theme from '../../js/styles/theme.style';

const useStyles = makeStyles(() => createStyles({
  circleBackground: {
    fill: 'none',
    stroke: ({ progressPercentage }) => (progressPercentage === 100 ? '#FFCA04' : '#ddd')
  },
  circleProgress: {
    fill: 'none',
    stroke: '#FFCA04',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  text: {
    fontSize: '3em',
    fontWeight: 'bold',
    fill: 'red'
  },
  svg: {
    // cursor: 'pointer'
  }
}));

const CircularProgressBarForActivity = ({
  sqSize,
  strokeWidth,
  progressPercentage,
  src,
  alt,
  status,
  onClick,
  moduleCompletionMode
}) => {
  const progress = progressPercentage / 100;
  const classes = useStyles({ progressPercentage });

  // Size of the enclosing square
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;

  const arcStrokeWidth = 2;

  const startAngle = 3 * Math.PI / 2;
  const endAngle = startAngle - progress * (2 * Math.PI);
  const mainArcWidth = strokeWidth + arcStrokeWidth;
  const squareViewBoxSideLength = sqSize;
  const centerX = squareViewBoxSideLength / 2;
  const centerY = -squareViewBoxSideLength / 2;
  const mainArcRadius = (squareViewBoxSideLength + arcStrokeWidth) / 2;
  const mainArcInternalRadius = mainArcRadius - mainArcWidth;
  const startX = centerX + mainArcRadius * Math.cos(startAngle);
  const startY = -(centerY + mainArcRadius * Math.sin(startAngle));
  const endArcsRadius = mainArcWidth / 2;
  const firstEndArcEndX = centerX + mainArcInternalRadius * Math.cos(startAngle);
  const firstEndArcEndY = -(centerY + mainArcInternalRadius * Math.sin(startAngle));
  const mainArcInternalSideEndX = centerX + mainArcInternalRadius * Math.cos(endAngle);
  const mainArcInternalSideEndY = -(centerY + mainArcInternalRadius * Math.sin(endAngle));
  const secondEndArcEndX = centerX + mainArcRadius * Math.cos(endAngle);
  const secondEndArcEndY = -(centerY + mainArcRadius * Math.sin(endAngle));
  const endCirleCenterX = centerX + (mainArcRadius - mainArcWidth / 2) * Math.cos(endAngle);
  const endCirleCenterY = -(centerY + (mainArcRadius - mainArcWidth / 2) * Math.sin(endAngle));

  return (
    <svg
      width={sqSize}
      height={sqSize}
      viewBox={viewBox}
      className={classes.svg}
      onClick={onClick}
      x="50%"
      y="50%"
    >
      {moduleCompletionMode !== 'devoir' &&
        ['terminated', 'waiting_for_correction'].indexOf(status) > -1 && <circle
        className={classes.circleBackground}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={strokeWidth}
      />}
      {progress > 0 &&
      progress !== 1 &&
      moduleCompletionMode !== 'devoir' &&
       ['terminated', 'waiting_for_correction'].indexOf(status) > -1 &&
        (
          <>
            <path
              d={`
            M ${startX} ${startY}
            A ${endArcsRadius} ${endArcsRadius} 0 0 0 ${firstEndArcEndX} ${firstEndArcEndY}
            A ${mainArcInternalRadius} ${mainArcInternalRadius} 0 ${progress > 50 / 100 ? 1 : 0} 1 ${mainArcInternalSideEndX} ${mainArcInternalSideEndY}
            A ${endArcsRadius} ${endArcsRadius} 0 0 0 ${secondEndArcEndX} ${secondEndArcEndY}
            A ${mainArcRadius} ${mainArcRadius} 0 ${progress > 50 / 100 ? 1 : 0} 0 ${startX} ${startY}
          `}
              stroke="white"
              fill="#FFCA04"
              strokeWidth={arcStrokeWidth}
              fillOpacity="1"
            />
            <circle
              cx={endCirleCenterX}
              cy={endCirleCenterY}
              r={(mainArcWidth - strokeWidth) / 2}
              fill="#FFCA04"
            />
          </>
        )}
      <circle
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius - 16}
        fill={
          moduleCompletionMode !== 'devoir' &&
          ['terminated', 'waiting_for_correction'].indexOf(status) > -1
            ? theme.green[40]
            : theme.blue[400]
        }
        className={classes.circle}
      />
      <image
        alt={alt}
        x="28%"
        y="28%"
        width={80}
        height={80}
        textAnchor="middle"
        xlinkHref={src}
        clipPath="url(#myCircle)"
      />
    </svg>
  );
};

export default CircularProgressBarForActivity;
