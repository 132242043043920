import * as React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../../js/styles/theme.style';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    backgroundColor: theme.WHITE,
    alignItems: 'center',
    padding: 40,
    minHeight: '100vh'
  },
  nav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    margin: 'auto',
    maxWidth: 600,
    textAlign: 'center'
  },
  title: {
    margin: '16px 0',
    fontSize: 36,
    fontFamily: theme.FONT_FAMILY_BOLD,
    color: theme.BLACK_BLUE,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  subtitle: {
    textAlign: 'center',
    fontFamily: theme.FONT_FAMILY_BOLD,
    fontSize: 24,
    color: theme.BLACK_BLUE,
    fontWeight: 600
  },
  main: {
    margin: 'auto',
    maxWidth: 600,
  },
});

interface Props {
  navActions?: React.ReactElement;
  title?: string;
  subtitle?: string;
}

const BaseLayout: React.FC<Props> = ({
  navActions,
  title,
  subtitle,
  children
}) => {
  const classes = useStyles();

  return (<div className={classes.wrapper}>
    <nav className={classes.nav}>
      {navActions}
    </nav>

    <header className={classes.header}>
      <Typography className={classes.title} component="h1">
        {title}
      </Typography>

      {subtitle && <Typography className={classes.subtitle} component="p">
        {subtitle}
      </Typography>}
    </header>

    <main className={classes.main}>
      {children}
    </main>
  </div>
  );
};

export default BaseLayout;
