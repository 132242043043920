import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  p: {
    textAlign: 'center',
    fontSize: '18px'
  }
}));

const Text = ({ text, component = 'p' }) => {
  const classes = useStyles();

  return <Typography component={component}
    className={classes.p}
    color="textPrimary" >{text}</Typography>;
};

export default Text;
