import React from 'react';
import Typo from './Typo';
import { connect } from 'react-redux';
import { LEAVE_MODULE } from '../../js/actions';
import { getLtiInfo } from '../../js/selectors';
import { makeStyles, createStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../js/theme';

const useStyles = makeStyles(() => createStyles({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'inherit',
    outline: 0,
    color: theme.palette.primary.main,
    '&:focus-visible': {
      outlineColor: '#2260C5',
      outlineOffset: '-2px',
      outlineStyle: 'auto',
      outlineWidth: '5px'
    },
    cursor: 'pointer'
  },
  icon: {
    fontSize: 40,
    color: props => props.color
  }
}));

const CloseButton = ({
  isDisplayed,
  leaveModule,
  text,
  color = theme.grey[300]
}) => {
  const classes = useStyles({ color });

  return isDisplayed && <button className={classes.button} onClick={() => { leaveModule(); }} >
    <CloseIcon className={classes.icon}></CloseIcon>
    { text && <div style={{ margin: 10 }} ><Typo text={text} ></Typo> </div> }
  </button>;
};

const mapStateToProps = state => {
  const { isMaskoot } = getLtiInfo(state);
  return {
    isDisplayed: !isMaskoot
  };
};

const mapDispatchToProps = dispatch => ({
  leaveModule: () => dispatch({ type: LEAVE_MODULE })
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseButton);
